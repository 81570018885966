// @flow
import React from 'react'
import { Link } from 'gatsby'

import '../../styles/style.scss'

const Layout = ({ children }: any) => {
  return (
    <div className="app">
      {/* Header */}
      <header id="header-global" className="header-global">
        <Link to="/" className="site-id">
          <span className="headline">Uwe-Christian Metz</span>
          <span className="subtitle">Architekt</span>
        </Link>

        <nav className="menu-primary">
          <ul>
            <li>
              <Link to="/" title="Home">
                Home
              </Link>
            </li>
            <li>
              <Link to="/projekte" title="Projekte">
                Projekte
              </Link>
            </li>
            <li>
              <Link to="/kontakt" title="Kontakt">
                Kontakt
              </Link>
            </li>
          </ul>
        </nav>
      </header>

      {/* Main */}
      <main id="main-global" className="main-global">
        {children}
      </main>

      {/* Footer */}
      <footer id="footer-global" className="footer-global">
        <nav className="menu-secondary">
          <ul>
            <li>
              <Link to="/datenschutz">Datenschutz</Link>
            </li>
            <li>
              <Link to="/impressum">Impressum</Link>
            </li>
          </ul>
        </nav>
      </footer>
    </div>
  )
}

export default Layout
